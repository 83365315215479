import React from "react"
import { graphql, navigate } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../Layout"
import Navbar from "../Navbar"
import Footer from "../Footer"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MdOutlineKeyboardBackspace } from "react-icons/md"
import { Heading20, Heading32 } from "../../styles/PageStyles"

const EventsTemplate = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  let eventsTitle
  const { title, events } = data?.markdownRemark?.frontmatter
  if (langKey === "en") {
    eventsTitle = "Events"
  } else {
    eventsTitle = "Kegiatan"
  }

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout>
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid>
          <BackBtn onClick={() => navigate(-1)}>
            <MdOutlineKeyboardBackspace size="25" />
            <Heading20>{eventsTitle}</Heading20>
          </BackBtn>
          <div>
            <Heading32>{title}</Heading32>
          </div>
          {events.map((event, index) => (
            <div className="images-container" key={index}>
              <Heading32>{event.title}</Heading32>
              {event.images.map((item, imageIndex) => (
                <GatsbyImage
                  key={imageIndex}
                  image={getImage(item.image)}
                  alt={`${title} image ${index + 1}`}
                />
              ))}
            </div>
          ))}
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default EventsTemplate

const StyledContainer = styled(Container)`
  padding: 140px 10% 75px 10%;
  min-height: 80vh;

  h1 {
    margin-bottom: 50px;
  }

  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .gatsby-image-wrapper {
      margin-bottom: 50px;
    }
  }

  @media only screen and (max-width: 768px) {
    h1 {
      margin-bottom: 25px;
    }

    .images-container {
      .gatsby-image-wrapper {
        margin-bottom: 25px;
      }
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    h1 {
      margin-bottom: 25px;
    }

    .images-container {
      width: 65%;
    }
  }
`

const BackBtn = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  background: #fff;
  cursor: pointer;

  &:hover {
    svg {
      transform: translateX(-5px);
      transition: 0.3s ease-in-out;
    }
  }

  h1 {
    margin: 0;
  }

  svg {
    fill: #0073af;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
  }
`

export const query = graphql`
  query EventsTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        events {
          title
          images {
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 1200
                  aspectRatio: 1.5
                )
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
